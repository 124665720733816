import React, { useContext } from "react";
import SearchContext from "../../context/searchContext";
import { useParams } from "react-router-dom";

const BreadCrumb = ({ pages }) => {
  const { lang, setLang } = useContext(SearchContext);
  const { id, lan } = useParams();

  return (
    <div>
      <nav aria-label="breadcrumb ">
        <ol className="breadcrumb mt-0 mb-0 breadcrumb-size">
          {pages?.map((page, i) => (
            <li key={i} className="breadcrumb-item ps-0">
              <a
                style={{ color: "white", textDecoration: "none" }}
                href={page.href}
              >
                {lan === "en" ? page.val : page.valAr}
              </a>
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};

export default BreadCrumb;
