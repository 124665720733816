import { useState, useContext } from "react";
import { Accordion } from "react-bootstrap";
import SearchContext from "../context/searchContext";
import { useParams } from "react-router-dom";

const Faqs = () => {
  const { lang, setlang } = useContext(SearchContext);
  const { id, lan } = useParams();

  const faqsData = [
    {
      id: 1,
      title: `${
        lan === "en"
          ? "All available withdrawal methods?"
          : "ما هي طرق السحب المتوفرة؟"
      }`,
      answer: `${
        lan === "en"
          ? "Perfect Money, USDT, Mastercard & Visa card."
          : "فيزا كارد، ماستر كارد، بيرفكت موني، والمحفظات الرقمية"
      }`,
    },
    {
      id: 2,
      title: `${
        lan === "en"
          ? "Is there a welcome bonus available?"
          : "هل يتوفر بونص ترحيبي؟"
      }`,
      answer: `${
        lan === "en"
          ? "There is no welcome bonus available at PrimeX because it is an ECN company that connects clients to liquidity providers. However, we do offer a standard account with a minimum deposit of $10, which you can start with."
          : "كلا، لا يتوفر بونص ترحيبي، حيث نحن برايم اكس شركة تعمل بنظام الإي سي أن الذي يربط العميل مباشرةً بمزودي السيولة. بخلاف ذلك فنحن نوفر حساب ستاندرد يتيح لك بدء التداول بإيداع أولي قدره 10 دولارات فقط."
      }`,
    },
    {
      id: 3,
      title: `${
        lan === "en" ? "What is minimum deposit?" : "ما هو الحد الأدنى للإيداع؟"
      }`,
      answer: `${
        lan === "en"
          ? "The minimum deposit and withdrawal amounts at PrimeX are $10."
          : "الحد الادنى للسحب والإيداع في برايم اكس هو 10 دولارات."
      }`,
    },
  ];
  const [active, setActive] = useState(faqsData[0].id);

  return (
    <>
      <div id="tab1" className="tab_content">
        <Accordion
          defaultActiveKey={faqsData[0].id}
          as={"ul"}
          className="accordion"
          style={{ textAlign: "left", paddingLeft: "0px" }}
        >
          {faqsData.map((faq) => (
            <li key={faq.id}>
              <Accordion.Toggle
                as="a"
                eventKey={faq.id}
                onClick={() => setActive(faq.id === active ? null : faq.id)}
                className={faq.id === active ? "active" : ""}
              >
                <span>
                  {" "}
                  {/* <FormattedMessage id={`question_${faq.id}`} />{" "} */}
                  {faq.title}
                </span>
              </Accordion.Toggle>
              <Accordion.Collapse
                eventKey={faq.id}
                style={lan === "ar" ? { textAlign: "right" } : null}
              >
                <p className="px-5">
                  {/* <FormattedMessage id={`answer_${faq.id}`} /> */}
                  {faq.answer}
                </p>
              </Accordion.Collapse>
            </li>
          ))}
        </Accordion>
      </div>
    </>
  );
};
export default Faqs;
