import { testimonial_list_slider } from "../components/sliderProps";
import { Link } from "react-router-dom";
import { Nav, Tab } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
// import Image from "next/image";
import { useEffect, useContext, useState } from "react";
import {
  FaCheck,
  FaStar,
  FaRegArrowAltCircleRight,
  FaRegArrowAltCircleLeft,
} from "react-icons/fa";
import Counter from "../components/Counter";
// import Faqs from "../components/Faqs";
import LogoSlider from "../components/LogoSlider";
import TeamSlider from "../components/TeamSlider";
import SearchContext from "../context/searchContext";
import { useForm } from "react-hook-form";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import Faqs from "../components/Faqs";
import { useParams } from "react-router-dom";

const About = () => {
  const { lang, setlang } = useContext(SearchContext);
  const { id, lan } = useParams();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "onchange" });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `https://primexbroker.com/api/contact`,
        data
      );
      if (res?.data?.success) {
        setLoading(false);
        reset();
        showModal();
        setTimeout(handleOk, 5000);
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.log(err, "here is the error");
    }
  };

  useEffect(() => {
    console.log(window.innerWidth);
  }, []);
  return (
    <>
      {/* ========================================================= */}
      {/* Start abouts area  */}
      {/* ========================================================= */}
      <div className="abouts_areas">
        <div className="container abouts_container">
          <div
            className="row"
            style={lan === "ar" ? { textAlign: "right" } : null}
            dir={lan === "ar" ? "ltr" : ""}
          >
            <div className="col-lg-6 col-md-6">
              <div className="singles_abouts_boxs">
                <div className="abouts_thumb">
                  <img
                    src="/assets/images/about/vision.webp"
                    alt="mission"
                    style={{ borderRadius: 10, opacity: 0.9, width: "100%" }}
                  />
                  <div className="about-inner-content">
                    <div
                      className="abouts_titles"
                      style={
                        lan === "ar"
                          ? { textAlign: "right" }
                          : { textAlign: "left" }
                      }
                    >
                      <h3>
                        {lan === "en"
                          ? "PrimeX Broker aims for ethical, constant growth in a dynamic industry."
                          : "تطمح برايم اكس لنمو ثابت، وعالي المعايير في مجال دائم التغير"}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="singles_abouts_boxs">
                <div className="abouts_thumb">
                  <img
                    src="/assets/images/about/mission.webp"
                    alt="mission"
                    style={{ borderRadius: 10, opacity: 0.9, width: "100%" }}
                  />
                  <div
                    className="abouts_titles upper"
                    style={
                      lan === "ar"
                        ? { textAlign: "right" }
                        : { textAlign: "left" }
                    }
                  >
                    <h3>
                      {lan === "en"
                        ? "Enabling global trading with safe, user-friendly tools for all traders."
                        : "إتاحة التداول، باستخدام أدوات سهلة ومحببة للمستخدمين"}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*=================================================*/}
      {/* START  feture-area Section */}
      {/*=================================================*/}
      <div
        className="feature-area style-two"
        style={{
          backgroundImage: "url(/assets/images/resource/why-choose-bg.png)",
        }}
      >
        <div className="container">
          <div
            className="row"
            style={lan === "ar" ? { textAlign: "right" } : null}
            dir={lan === "ar" ? "rtl" : "ltr"}
          >
            <div className="col-lg-6 col-md-12">
              <div
                className="consen-section-title"
                style={
                  lan === "ar" ? { textAlign: "right" } : { textAlign: "left" }
                }
              >
                <h5
                  style={
                    lan === "ar"
                      ? { textAlign: "right" }
                      : { textAlign: "left" }
                  }
                >
                  {lan === "en" ? "Why Choose Us?" : "لماذا برايم اكس؟"}
                </h5>
                <h2
                  style={
                    lan === "ar"
                      ? { textAlign: "right" }
                      : { textAlign: "left" }
                  }
                >
                  {" "}
                  {lan === "en"
                    ? "The Fastest and Most Secure "
                    : "الطريق الأسرع والأكثر أماناً"}
                  <span>{lan === "en" ? "Way to Achieve" : " لتحقيق"}</span>
                  <span> {lan === "en" ? "Your Profits" : "العوائد"}</span>
                </h2>
                <p
                  className="about-text1"
                  style={
                    lan === "ar"
                      ? { textAlign: "right" }
                      : { textAlign: "left" }
                  }
                >
                  {" "}
                  {lan === "en"
                    ? "As a strong proponent of transparency, we establish the highest standards of safety for our clients’ funds."
                    : "بصفتنا نصيراً قوياً للشفافية، فلقد اتخذنا معايير أمان عالية للحفاظ على أموال عملائنا."}
                </p>
              </div>
              <div
                className="dreamit-icon-list"
                dir={lan === "ar" ? "rtl" : "ltr"}
              >
                <ul
                  style={
                    lan === "ar"
                      ? { textAlign: "right" }
                      : { textAlign: "left" }
                  }
                  className="d-flex flex-column ps-0"
                >
                  <li>
                    {lan === "ar" ? (
                      <FaRegArrowAltCircleLeft style={{ marginLeft: "10px" }} />
                    ) : (
                      <FaRegArrowAltCircleRight
                        style={{ marginRight: "10px" }}
                      />
                    )}
                    <span>
                      {" "}
                      {lan === "en" ? "Regulated Broker" : "وسيط مرخص"}
                    </span>
                  </li>
                  <li>
                    {lan === "ar" ? (
                      <FaRegArrowAltCircleLeft style={{ marginLeft: "10px" }} />
                    ) : (
                      <FaRegArrowAltCircleRight
                        style={{ marginRight: "10px" }}
                      />
                    )}
                    <span>
                      {" "}
                      {lan === "en"
                        ? "Fast Deposits & Withdrawals"
                        : "تحويلات مالية سريعة"}
                    </span>
                  </li>
                  <li>
                    {lan === "ar" ? (
                      <FaRegArrowAltCircleLeft style={{ marginLeft: "10px" }} />
                    ) : (
                      <FaRegArrowAltCircleRight
                        style={{ marginRight: "10px" }}
                      />
                    )}
                    <span>
                      {" "}
                      {lan === "en" ? "Security of Funds" : "أمن الودائع"}
                    </span>
                  </li>
                  <li>
                    {lan === "ar" ? (
                      <FaRegArrowAltCircleLeft style={{ marginLeft: "10px" }} />
                    ) : (
                      <FaRegArrowAltCircleRight
                        style={{ marginRight: "10px" }}
                      />
                    )}
                    <span>
                      {" "}
                      {lan === "en"
                        ? "Diamond Trading Conditions"
                        : "شروط تداول ماسية"}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-10 col-sm-10">
              {/* Hero */}
              <Tab.Container defaultActiveKey={"t1"}>
                <div className="tab">
                  <Nav as="ul" className="tabs mx-2">
                    <li>
                      <Nav.Link className="c-pointer" as="a" eventKey={"t1"}>
                        {lan === "en" ? "Full Satisfaction" : "الرضا الكامل"}
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="c-pointer" as="a" eventKey={"t2"}>
                        {lan === "en" ? "Mission" : "مهمة"}
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="c-pointer" as="a" eventKey={"t3"}>
                        {lan === "en" ? "Vision" : "رؤية"}
                      </Nav.Link>
                    </li>
                  </Nav>{" "}
                  {/* / tabs */}
                  <Tab.Content className="tab_content">
                    <Tab.Pane eventKey={"t1"} className="tabs_item ">
                      <div className="mbl-item">
                        <img
                          src="/assets/images/about/VALUE.png"
                          alt="business"
                          className={"img-center"}
                        />
                        <ul
                          className="tabs-inner-list d-flex flex-column ps-0"
                          style={
                            lan === "ar"
                              ? { textAlign: "right" }
                              : { textAlign: "left" }
                          }
                        >
                          <li>
                            <i
                              className="fas fa-check pl-2"
                              style={
                                lan === "ar"
                                  ? { marginLeft: "12px" }
                                  : { marginRight: "12px" }
                              }
                            />
                            <span>
                              {lan === "en"
                                ? "Young force in CFD markets."
                                : "قوة صاعدة في أسواق الـCFD."}
                            </span>
                          </li>
                          <li>
                            <i
                              className="fas fa-check pl-2"
                              style={
                                lan === "ar"
                                  ? { marginLeft: "12px" }
                                  : { marginRight: "12px" }
                              }
                            />
                            <span>
                              {lan === "en"
                                ? "Trusted prime broker."
                                : "وسيط موثوق."}
                            </span>
                          </li>
                          <li>
                            <i
                              className="fas fa-check pl-2"
                              style={
                                lan === "ar"
                                  ? { marginLeft: "12px" }
                                  : { marginRight: "12px" }
                              }
                            />
                            <span>
                              {lan === "en"
                                ? "Careful handling of clients problems."
                                : "تعامل احترافي مع احتياجات العملاء."}
                            </span>
                          </li>
                          <li>
                            <i
                              className="fas fa-check pl-2"
                              style={
                                lan === "ar"
                                  ? { marginLeft: "12px" }
                                  : { marginRight: "12px" }
                              }
                            />
                            <span>
                              {lan === "en"
                                ? "Diversity of financial instruments."
                                : "تنوع الأدوات المالية."}
                            </span>
                          </li>
                          <li>
                            <i
                              className="fas fa-check pl-2"
                              style={
                                lan === "ar"
                                  ? { marginLeft: "12px" }
                                  : { marginRight: "12px" }
                              }
                            />
                            <span>
                              {lan === "en"
                                ? "Access deep market liquidity."
                                : "الوصول إلى سيولة السوق العميقة"}
                            </span>
                          </li>
                          <li>
                            <i
                              className="fas fa-check pl-2"
                              style={
                                lan === "ar"
                                  ? { marginLeft: "12px" }
                                  : { marginRight: "12px" }
                              }
                            />
                            <span>
                              {lan === "en"
                                ? "Competitive trading conditionsy."
                                : "شروط تداول تنافسية."}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </Tab.Pane>{" "}
                    {/* / tabs_item */}
                    <Tab.Pane eventKey={"t2"} className="tabs_item ">
                      <div className="mbl-item">
                        <img
                          className={"img-center"}
                          src="/assets/images/about/MISSION.png"
                          alt="mission"
                        />

                        <ul
                          className="tabs-inner-list d-flex flex-column"
                          style={
                            lan === "ar"
                              ? { textAlign: "right" }
                              : { textAlign: "left" }
                          }
                        >
                          <li>
                            <i
                              className="fas fa-check pl-2"
                              style={
                                lan === "ar"
                                  ? { marginLeft: "12px" }
                                  : { marginRight: "12px" }
                              }
                            />
                            <span>
                              {lan === "en"
                                ? "Create a global trading environment."
                                : "بيئة تداول عالمية."}
                            </span>
                          </li>
                          <li>
                            <i
                              className="fas fa-check pl-2"
                              style={
                                lan === "ar"
                                  ? { marginLeft: "12px" }
                                  : { marginRight: "12px" }
                              }
                            />
                            <span>
                              {lan === "en"
                                ? "provide safe and sober tools."
                                : "أدوات آمنة وفعالة."}
                            </span>
                          </li>
                          <li>
                            <i
                              className="fas fa-check pl-2"
                              style={
                                lan === "ar"
                                  ? { marginLeft: "12px" }
                                  : { marginRight: "12px" }
                              }
                            />
                            <span>
                              {lan === "en"
                                ? "become s trusted global brand."
                                : "أن نكون علامةً مسجلة وموثوقة في العالم."}
                            </span>
                          </li>
                          <li>
                            <i
                              className="fas fa-check pl-2"
                              style={
                                lan === "ar"
                                  ? { marginLeft: "12px" }
                                  : { marginRight: "12px" }
                              }
                            />
                            <span>
                              {lan === "en"
                                ? "provide seamless, secure and transparent trading experience."
                                : "توفير تجربة تداولية شفافة وآمنة، وسهلة."}
                            </span>
                            <br />
                          </li>
                        </ul>
                      </div>
                    </Tab.Pane>{" "}
                    {/* / tabs_item */}
                    <Tab.Pane eventKey={"t3"} className="tabs_item ">
                      <div className="mbl-item">
                        <img
                          className={"img-center"}
                          src="/assets/images/about/VISION.png"
                          alt="vision"
                        />

                        <ul
                          className="tabs-inner-list d-flex flex-column"
                          style={
                            lan === "ar"
                              ? { textAlign: "right" }
                              : { textAlign: "left" }
                          }
                        >
                          <li>
                            <i
                              className="fas fa-check pl-2"
                              style={
                                lan === "ar"
                                  ? { marginLeft: "12px" }
                                  : { marginRight: "12px" }
                              }
                            />
                            <span>
                              {" "}
                              {lan === "en"
                                ? "Establish its ethics and services."
                                : "بيان الأخلاقيات والخدمات"}
                            </span>
                          </li>
                          <li>
                            <i
                              className="fas fa-check pl-2"
                              style={
                                lan === "ar"
                                  ? { marginLeft: "12px" }
                                  : { marginRight: "12px" }
                              }
                            />
                            <span>
                              {lan === "en"
                                ? "Technological advancements"
                                : "التطور التكنولوجي"}
                            </span>
                          </li>
                          <li>
                            <i
                              className="fas fa-check pl-2"
                              style={
                                lan === "ar"
                                  ? { marginLeft: "12px" }
                                  : { marginRight: "12px" }
                              }
                            />
                            <span>
                              {lan === "en"
                                ? "Education, growth, and profitability."
                                : "التعلم، والنمو، والاستفادة"}
                            </span>
                          </li>
                          <li>
                            <i
                              className="fas fa-check pl-2"
                              style={
                                lan === "ar"
                                  ? { marginLeft: "12px" }
                                  : { marginRight: "12px" }
                              }
                            />
                            <span>
                              {" "}
                              {lan === "en"
                                ? "Create a thriving community."
                                : "انشاء مجتمع مندفع وشغوف لمجال التداول"}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </Tab.Pane>{" "}
                    {/* / tabs_item */}
                  </Tab.Content>{" "}
                  {/* / tab_content */}
                </div>
              </Tab.Container>
              {/* / tab */}
            </div>
          </div>
        </div>
      </div>
      {/*==================================================*/}
      {/* Start consen Testimonial Area */}
      {/*==================================================*/}
      <div
        className="testimonial-area"
        style={{
          backgroundImage: "url(/assets/images/resource/testimonial-bg.png)",
        }}
      >
        <div
          className="container testi-container"
          dir={lan === "ar" ? "ltr" : "ltr"}
        >
          <div
            dir={lan === "ar" ? "rtl" : "ltr"}
            className="row testi-rotate align-items-center"
          >
            <div className="col-lg-7 col-md-6">
              <div
                className="consen-section-title testimonial-area-title white"
                // style={{ paddingBottom: "50px", textAlign: "left" }}
              >
                <h5
                  style={
                    lan === "ar" ? { textAlign: "right", width: "100%" } : null
                  }
                  className="text-white"
                >
                  {" "}
                  {lan === "en" ? "Testimonials" : "الآراء"}
                </h5>
                <h2
                  style={lan === "ar" ? { textAlign: "right" } : null}
                  className="text-white"
                >
                  {" "}
                  <span style={{ color: "#FCCD0A" }}>
                    {lan === "en" ? "PrimeX" : ""}
                  </span>{" "}
                  {lan === "en" ? "Trusted Customers" : ""}
                </h2>
                <h2
                  style={lan === "ar" ? { textAlign: "right" } : null}
                  className="text-white"
                >
                  {" "}
                  {lan === "en" ? "Awesome" : ""}
                  <span style={{ color: "white" }}>
                    {" "}
                    {lan === "en" ? "Reviews" : ""}
                  </span>
                </h2>
              </div>
            </div>
            <div className="col-lg-5 col-md-6">
              <div className="row">
                <div className="col-6">
                  <div className="testi-counter-box upper">
                    <div
                      className="testi-counter-title"
                      style={
                        lan === "ar"
                          ? { textAlign: "right" }
                          : { textAlign: "left" }
                      }
                    >
                      <h3 className="counter mb-0">
                        {" "}
                        <Counter end={1990} />{" "}
                      </h3>
                      <span style={{ color: "#FCCD0A" }}>+</span>
                      <p> {lan === "en" ? "Reviews" : "مراجعات"}</p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div
                    className="testi-counter-box"
                    style={
                      lan === "ar"
                        ? { textAlign: "right" }
                        : { textAlign: "left" }
                    }
                  >
                    <div className="testi-counter-title">
                      <h3 className="counter">
                        {" "}
                        <Counter end={4.8} decimals={1} />{" "}
                      </h3>
                      {/* <span>%</span> */}
                      <p> {lan === "en" ? "Rating" : "تقييم"}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="testi-shape-thumb1 rotateme d-lg-block d-none"
              style={{ width: "auto", padding: "0px" }}
            >
              <img
                src="/assets/images/resource/testimonial-map.png"
                alt="testimonial-map"
              />
            </div>
          </div>
          <div className="row">
            <Swiper
              {...testimonial_list_slider}
              className="testimonial_list owl-carousel"
            >
              <SwiperSlide className="pr-1 support-testimonial-slider">
                <div
                  className="testimonial-single-box"
                  style={{ height: "258px" }}
                >
                  <div className="testimonial-content1">
                    <div className="single-quote-icon">
                      <div className="quote-thumb">
                        <img
                          src="/assets/images/resource/testi8.png"
                          alt="testi8"
                        />
                      </div>
                      <div className="quote-title">
                        <h4> M. Ahmed</h4>
                        <p>IQ</p>
                      </div>
                    </div>
                    <div className="em-testimonial-text">
                      <p style={lan === "ar" ? { textAlign: "right" } : null}>
                        {lan === "en"
                          ? "I send greatings and thankful to support agent department for fast reply and accurate answers"
                          : "أرسل تحياتي وأشكر قسم الدعم الوكيل على الرد السريع والإجابات الدقيقة"}
                      </p>
                    </div>
                    <div className="em-testi-start-icon">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="pr-1 support-testimonial-slider">
                <div
                  className="testimonial-single-box"
                  style={{ height: "258px" }}
                >
                  <div className="testimonial-content1">
                    <div className="single-quote-icon">
                      <div className="quote-thumb">
                        <img
                          src="/assets/images/resource/testi3.png"
                          alt="testi3"
                        />
                      </div>
                      <div className="quote-title">
                        <h4> Ali Muhsin </h4>
                        <p> IQ </p>
                      </div>
                    </div>
                    <div className="em-testimonial-text">
                      <p style={lan === "ar" ? { textAlign: "right" } : null}>
                        {lan === "en"
                          ? "Thank you primeX support team and epically Mr. Ahmed Ali for your kind support"
                          : "شكراً لفريق دعم PrimeX وبالأخص السيد أحمد علي على دعمك الكريم"}
                      </p>
                    </div>
                    <div className="em-testi-start-icon">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="pr-1 support-testimonial-slider">
                <div
                  className="testimonial-single-box"
                  style={{ height: "258px" }}
                >
                  <div className="testimonial-content1">
                    <div className="single-quote-icon">
                      <div className="quote-thumb">
                        <img
                          src="/assets/images/resource/testi2.png"
                          alt="testi2"
                        />
                      </div>
                      <div className="quote-title">
                        <h4> Muhammad Ismael </h4>
                        <p> TR </p>
                      </div>
                    </div>
                    <div className="em-testimonial-text">
                      <p style={lan === "ar" ? { textAlign: "right" } : null}>
                        {lan === "en"
                          ? "I would like to thank Support Team here especially Mariam Nassir for his Rapid Response"
                          : "أود أن أشكر فريق الدعم هنا وخاصة مريم ناصر على استجابته السريعة"}
                      </p>
                    </div>
                    <div className="em-testi-start-icon">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="pr-1 support-testimonial-slider">
                <div
                  className="testimonial-single-box"
                  style={{ height: "258px" }}
                >
                  <div className="testimonial-content1">
                    <div className="single-quote-icon">
                      <div className="quote-thumb">
                        <img src="/assets/images/resource/testi4.png" alt />
                      </div>
                      <div className="quote-title">
                        <h4> Moamal Salam </h4>
                        <p> AE </p>
                      </div>
                    </div>
                    <div
                      className="em-testimonial-text"
                      style={
                        lan === "ar"
                          ? { textAlign: "right" }
                          : { textAlign: "left" }
                      }
                    >
                      <p
                        style={
                          lan === "ar"
                            ? { textAlign: "right" }
                            : { textAlign: "left" }
                        }
                      >
                        {lan === "en"
                          ? "She is good in her work and she has good information about her work .she is also good in It programmer"
                          : "فريق العمل جيد جدا ورائع في إدارة عمليات البرمجة"}
                      </p>
                    </div>
                    <div className="em-testi-start-icon">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="pr-1 support-testimonial-slider">
                <div
                  className="testimonial-single-box"
                  style={{ height: "258px" }}
                >
                  <div className="testimonial-content1">
                    <div className="single-quote-icon">
                      <div className="quote-thumb">
                        <img src="/assets/images/resource/testi5.png" alt />
                      </div>
                      <div className="quote-title">
                        <h4> Mohammed Amar </h4>
                        <p> AE </p>
                      </div>
                    </div>
                    <div className="em-testimonial-text">
                      <p style={lan === "ar" ? { textAlign: "right" } : null}>
                        {lan === "en"
                          ? "One of the best companies that I dealt with for trade, and all the employees work very efficiently"
                          : "من أفضل الشركات التي تعاملت معها للتجارة، وجميع الموظفين يعملون بكفاءة عالية"}
                      </p>
                    </div>
                    <div className="em-testi-start-icon">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="pr-1 support-testimonial-slider">
                <div
                  className="testimonial-single-box"
                  style={{ height: "258px" }}
                >
                  <div className="testimonial-content1">
                    <div className="single-quote-icon">
                      <div className="quote-thumb">
                        <img src="/assets/images/resource/testi6.png" alt />
                      </div>
                      <div className="quote-title">
                        <h4>Waad Anwar</h4>
                        <p>AE</p>
                      </div>
                    </div>
                    <div className="em-testimonial-text">
                      <p style={lan === "ar" ? { textAlign: "right" } : null}>
                        {lan === "en"
                          ? "I have been investing with them for a while now and confident that my money is in good hands $$$"
                          : "لقد كنت أستثمر معهم منذ فترة وأنا واثق من أن أموالي في أيد أمينة $$$"}
                      </p>
                    </div>
                    <div className="em-testi-start-icon">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="pr-1 support-testimonial-slider">
                <div
                  className="testimonial-single-box"
                  style={{ height: "258px" }}
                >
                  <div className="testimonial-content1">
                    <div className="single-quote-icon">
                      <div className="quote-thumb">
                        <img src="/assets/images/resource/testi7.png" alt />
                      </div>
                      <div className="quote-title">
                        <h4> Noor Hassan </h4>
                        <p> IQ </p>
                      </div>
                    </div>
                    <div className="em-testimonial-text">
                      <p style={lan === "ar" ? { textAlign: "right" } : null}>
                        {lan === "en"
                          ? "Good reputation and good support team ( one of the best team member “ meryam hussam )"
                          : " سمعة جيدة وفريق دعم جيد (من أفضل أعضاء الفريق مريم حسام)"}
                      </p>
                    </div>
                    <div className="em-testi-start-icon">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <div className="owl-dots"></div>
            </Swiper>
            {/* <div className="testi-shape">
                <div className="testi-shape-thumb">
                  <img src="assets/images/resource/all-shape5.png" alt />
                </div>
              </div> */}
          </div>
        </div>
      </div>
      {/*==================================================*/}
      {/* Start counter Area */}
      <div
        className="counter-section style-33"
        style={{
          paddingTop: "30px",
          paddingBottom: "80px",
          backgroundImage: "url(/assets/images/resource/counter-bg23.jpg)",
        }}
      >
        <div className="container">
          <div className="counter-container">
            <div
              className="row hr pt-40 mob-review-stat"
              dir={lan === "ar" ? "ltr" : "ltr"}
            >
              <div className="col-md-6 col-lg-4">
                <div
                  className="counter-single-item-inner d-flex justify-content-start"
                  // style={
                  //   lan === "ar"
                  //     ? { justifyContent: "right" }
                  //     : { justifyContent: "left" }
                  // }
                >
                  <div className="counter-content">
                    <div className="counter-text">
                      <h1>
                        <span className="counter">
                          <Counter end={35} />k
                        </span>
                      </h1>

                      <span />
                      <div
                        className="counter-title"
                        style={
                          lan === "ar"
                            ? { textAlign: "left" }
                            : { textAlign: "left" }
                        }
                      >
                        <h4>{lan === "en" ? "Total" : "مجموع"}</h4>
                        <h3>{lan === "en" ? "Clients" : "العملاء"}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div
                  className="counter-single-item-inner d-flex justify-content-start"
                  // style={
                  //   lan === "ar"
                  //     ? { justifyContent: "right" }
                  //     : { justifyContent: "left" }
                  // }
                >
                  <div className="counter-content">
                    <div className="counter-text">
                      <h1>
                        <span className="counter">
                          <Counter end={2} />k
                        </span>
                      </h1>
                      <span />
                      <div
                        className="counter-title"
                        style={
                          lan === "ar"
                            ? { textAlign: "left" }
                            : { textAlign: "left" }
                        }
                      >
                        <h4>{lan === "en" ? "Total" : "مجموع"}</h4>
                        <h3>{lan === "en" ? "Reviews" : "التعليقات"}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div
                  className="counter-single-item-inner d-flex justify-content-start"
                  // style={
                  //   lan === "ar"
                  //     ? { justifyContent: "right" }
                  //     : { justifyContent: "left" }
                  // }
                >
                  <div className="counter-content">
                    <div className="counter-text">
                      <h1>
                        <span className="counter">
                          <Counter end={98} />%
                        </span>
                      </h1>
                      <span />
                      <div
                        className="counter-title"
                        style={
                          lan === "ar"
                            ? { textAlign: "left" }
                            : { textAlign: "left" }
                        }
                      >
                        <h4>{lan === "en" ? "Satisfaction" : "معدل"}</h4>
                        <h3>{lan === "en" ? "Rate" : "الرضى"}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*==================================================*/}
      {/* Start consen Team Area */}
      {/*==================================================*/}
      {/* <div
        className="team_area style-two"
        style={{
          backgroundImage: "url(/assets/images/resource/why-choose-bg.png)",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="consen-section-title upper text-center"
                style={{ paddingBottom: "60px" }}
              >
                <h5>{lan === "en" ? "Team Members" : "أعضاء الفريق"}</h5>
                <h2>
                  {lan === "en"
                    ? "Let's Meet with Our Team"
                    : "التقِ بفريق موظفينا"}
                </h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <TeamSlider />
          </div>
        </div>
      </div> */}
      {/*==================================================*/}
      {/* End consen Team Area */}
      {/*==================================================*/}
      {/*==================================================*/}
      {/* Start faq Area */}
      {/*==================================================*/}
      <div className="faq-area ">
        <div className="container pb-3">
          <div className="row" dir={lan === "ar" ? "rtl" : "ltr"}>
            <div className="col-lg-5 col-md-5 col-sm-10 ps-0">
              {/* Start Accordion */}
              <div className="tab_container">
                <div
                  className="consen-section-title faq-title white mb-1"
                  style={
                    lan === "ar"
                      ? { color: "#FCCD0A", textAlign: "right" }
                      : { textAlign: "left" }
                  }
                >
                  <h5
                    style={
                      lan === "ar"
                        ? { textAlign: "right", color: "#FCCD0A" }
                        : { textAlign: "left", color: "#FCCD0A" }
                    }
                  >
                    {" "}
                    {lan === "en" ? "FAQ" : "الاستفسارات"}
                  </h5>
                  <h2
                    style={
                      lan === "ar"
                        ? { textAlign: "right", color: "#fff" }
                        : { textAlign: "left", color: "#fff" }
                    }
                  >
                    {" "}
                    {lan === "en" ? "Frequently Asked" : "أسئلة"}
                    <span style={{ color: "#FCCD0A" }}>
                      {" "}
                      {lan === "en" ? "Questions" : "شائعة"}
                    </span>
                  </h2>
                </div>
                <Faqs />
              </div>
              {/* End Accordion */}
            </div>
            <div
              className={`faq-margin
                ${
                  lan === "ar"
                    ? "col-lg-6 col-md-6 col-sm-10"
                    : "col-lg-6 col-md-6 col-sm-10"
                }
              `}
              style={
                lan === "ar" ? { marginRight: "50px" } : { marginLeft: "20px" }
              }
            >
              <div
                className="contract-form-bg"
                style={
                  lan === "ar" ? { textAlign: "right" } : { textAlign: "left" }
                }
              >
                <div
                  style={{
                    zIndex: 2,
                    position: "relative",
                    marginTop: -10,
                  }}
                  className="contact-form-title"
                >
                  <h4
                    style={
                      lan === "ar"
                        ? { textAlign: "right", color: "rgb(63, 63, 63)" }
                        : { textAlign: "left", color: "rgb(63, 63, 63)" }
                    }
                  >
                    {" "}
                    {lan === "en" ? "DROP US A LINE" : "للاستفسار"}
                  </h4>
                  <p
                    style={
                      lan === "ar"
                        ? { textAlign: "right", color: "rgb(63, 63, 63)" }
                        : { textAlign: "left", color: "rgb(63, 63, 63)" }
                    }
                  >
                    {lan === "en"
                      ? "We are available 24/7"
                      : "نحن متوفرون على مدار الساعة 24/7"}
                  </p>
                </div>
                <div className="contact_from">
                  <form onSubmit={handleSubmit(onSubmit)} id="dreamit-form">
                    <div className="row">
                      <div className="col-lg-12">
                        <div
                          className="form_box"
                          style={{ marginBottom: "20px" }}
                        >
                          <input
                            type="text"
                            className={lan === "ar" ? "pe-3" : null}
                            style={errors.name ? { borderColor: "red" } : null}
                            name="name"
                            placeholder={lan === "en" ? "Name *" : "* الاسم"}
                            {...register("name", { required: true })}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div
                          className="form_box"
                          style={{ marginBottom: "20px" }}
                        >
                          <input
                            type="email"
                            name="email"
                            className={lan === "ar" ? "pe-3" : null}
                            placeholder={
                              lan === "en"
                                ? "Your E-Mail *"
                                : "* عنوان البريد الالكتروني"
                            }
                            style={errors.email ? { borderColor: "red" } : null}
                            {...register("email", { required: true })}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div
                          className="form_box"
                          style={{ marginBottom: "20px" }}
                        >
                          <input
                            className={lan === "ar" ? "pe-3" : null}
                            type="number"
                            name="phone"
                            placeholder={
                              lan === "en" ? "Phone Number" : "رقم الهاتف"
                            }
                            {...register("contact")}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div
                          className="form_box"
                          style={{ marginBottom: "20px" }}
                        >
                          <textarea
                            className={lan === "ar" ? "pe-3" : null}
                            name="message"
                            id="message"
                            cols={30}
                            rows={10}
                            placeholder={
                              lan === "en"
                                ? "Write a Message"
                                : "اكتب استفسارك هنا"
                            }
                            {...register("message")}
                            defaultValue={""}
                          />
                        </div>
                        <div className="quote_button">
                          <button
                            style={loading ? { cursor: "not-allowed" } : null}
                            className="btn"
                            type="submit"
                          >
                            {" "}
                            {lan === "en" ? "Send" : "ارسال"}{" "}
                            {loading && (
                              <Spinner
                                animation="border"
                                role="status"
                                size="sm"
                              ></Spinner>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div id="status" />
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default About;
