import React, { useState , useContext} from "react";
import { data } from "./data";
import SearchChildList from "./searchChildList";
import SearchContext from "../../context/searchContext"
import { FaCheck, FaTimes } from "react-icons/fa";


export default function SearchList({ input, closePopup, listData }) {
  // listData
  const { list, setList } = useContext(SearchContext);

  console.log(list, "list at the end");
  return (
    <>
      {list.length > 0 && (
        <div className="container-searchList" id="modal" style={{ width: "100%" }}>
          <div className="text-end " onClick={closePopup} >
            <FaTimes className="hover" style={{  color: "rgb(63,63,63)"  }} />
          </div>
          {list.map((item) => {
            return (
              <>
                <SearchChildList
                  {...item}
                />
              </>
            );
          })}
        </div>
      )}
    </>
  );
}