// import React, { useState } from "react";
import CountUp from "react-countup";
import ReactVisibilitySensor from "react-visibility-sensor";
const Counter = ({ end, decimals, tag, color }) => {
  console.log(decimals, "decimal");
  return (
    <CountUp
      end={end ? end : 100}
      duration={3}
      decimals={decimals ? decimals : 0}
    >
      {({ countUpRef, start }) => (
        <ReactVisibilitySensor onChange={start} delayedCall>
          <em
            className="counter"
            style={{ color: color ? color : "rgb(252,205,10)" }}
            data-from="0"
            data-to={end}
            ref={countUpRef}
          >
            count
          </em>
        </ReactVisibilitySensor>
      )}
    </CountUp>
  );
};

export default Counter;
