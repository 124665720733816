import React, { Fragment } from "react";
import Navbar from "../header/navbar";
import Footer from "../footer/footer";


const Layout = ({ children }) => {
    return (
        <Fragment>
            <Navbar />
            {children}
            <Footer />
        </Fragment>
    )
}

export default Layout