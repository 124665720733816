import React, { useContext } from "react";
import SearchContext from "../../context/searchContext";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function SearchChildList({ _id, title, titleAr, category }) {
  console.log(category, "category");
  const { lang, setLang } = useContext(SearchContext);
  const navigate = useNavigate();
  const { id, lan } = useParams();

  return (
    <>
      <div
        className="content-searchList"
        key={_id}
        onClick={() => navigate(`/article/detail/${_id}/${lan}`)}
      >
        <div className="flex">
          {/* <img src={image} alt={title} className="content-image-searchList" /> */}
          <p
            className="mleft"
            style={{ fontSize: "16px", textAlign: "left", marginBottom: "0px" }}
          >
            {`${lang === "en" ? category.title : category.titleAr}  → ${
              lang === "en" ? title : titleAr
            } `}{" "}
          </p>
        </div>
        {/* <div>
          <button className="close-btn" onClick={removeItem}>
            ×
          </button>
        </div> */}
      </div>
    </>
  );
}
