import React, { useEffect, useState, useContext } from "react";
import Layout from "../components/layout/layout";
import Search from "../components/search/search";
import { Link } from "react-router-dom";
import BreadCrumb from "../components/header/breadcrumb";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SearchContext from "../context/searchContext";
import axios from "axios";
const ArticlesList = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [input, setInput] = useState("");
  const [articles, setArticles] = useState([]);
  const [full, setfull] = useState(false);
  const { id, lan } = useParams();
  const { lang, setLang } = useContext(SearchContext);
  const { list, setList } = useContext(SearchContext);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  console.log(lang, "<<<<lang");
  // const articles = [
  //     {
  //         val: "Who is Primex?",
  //         id: ""
  //     },
  //     {
  //         val: "Who is Primex?",
  //         id: ""
  //     },
  //     {
  //         val: "Who is Primex?",
  //         id: ""
  //     },
  //     {
  //         val: "Who is Primex?",
  //         id: ""
  //     },

  //     {
  //         val: "Who is Primex?",
  //         id: ""
  //     },
  //     {
  //         val: "Who is Primex?",
  //         id: ""
  //     },
  //     {
  //         val: "Who is Primex?",
  //         id: ""
  //     },
  //     {
  //         val: "Who is Primex?",
  //         id: ""
  //     },
  //     {
  //         val: "Who is Primex?",
  //         id: ""
  //     },
  //     {
  //         val: "Who is Primex?",
  //         id: ""
  //     },
  // ]

  console.log(articles, "articles");

  const fetchArticlesByCategory = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API}/api/fetch/published/atricles/${id}`
      );
      console.log(res, "res");
      if (res.data.success) {
        setArticles(res.data.data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleInput = (val) => {
    setInput(val);
  };

  useEffect(() => {
    fetchArticlesByCategory();
  }, [id]);

  const SearchData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API}/api/fetch/all/search/${input}`
      );
      if (res.data.success) {
        console.log(res.data.data, "search result");
        setShowPopup(true);
        setList(res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (input !== "") {
      SearchData();
    }
  }, [input]);

  const bread = [
    { val: "Home", href: `/${lan}`, valAr: "الصفحة الرئيسية" },
    {
      val: articles[0]?.category?.title,
      href: "#",
      valAr: articles[0]?.category?.titleAr,
    },
  ];
  console.log(bread);

  if (loading) {
    return (
      <Layout>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "50vh",
          }}
        >
          <div class="spinner-border text-warning" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div dir={lan === "en" ? "ltr" : "rtl"}>
        <div
          style={{ backgroundColor: "rgb(64, 64, 64)" }}
          className="pt-2 pb-2"
        >
          <div
            className="container px-4 px-sm-5"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <BreadCrumb pages={bread} />
            </div>
            <div className="article-nav">
              <Search
                value={input}
                height={45}
                popup={showPopup}
                onChange={(e) => handleInput(e.target.value)}
                setPopup={() => {
                  setShowPopup(true);
                }}
                closePopup={() => {
                  setShowPopup(false);
                }}
                marginB={5}
              />
            </div>
          </div>
        </div>

        <div className="container mt-5 mb-5 px-4 px-sm-5">
          <h3
            style={
              lan === "ar" ? { textAlign: "right" } : { textAlign: "left" }
            }
          >
            {" "}
            {lan === "en"
              ? bread[bread.length - 1].val
              : bread[bread.length - 1].valAr}{" "}
          </h3>
          <ul
            className="mt-4 list"
            style={{ letterSpacing: 1.5, fontSize: 16 }}
          >
            {articles.map((article, i) => {
              if (!full && i < 7) {
                return (
                  <li
                    key={i}
                    onClick={() =>
                      navigate(`/article/detail/${article._id}/${lan}`)
                    }
                    className="mt-1"
                    style={
                      lan === "ar"
                        ? { textAlign: "right" }
                        : { textAlign: "left" }
                    }
                  >
                    {lan === "en" ? article.title : article.titleAr}
                  </li>
                );
              } else if (full) {
                return (
                  <li
                    key={i}
                    onClick={() =>
                      navigate(`/article/detail/${article._id}/${lan}`)
                    }
                    className="mt-1"
                  >
                    {lan === "en" ? article.title : article.titleAr}
                  </li>
                );
              }
            })}
          </ul>
          {!full && articles.length > 7 && (
            <a
              className="seemore"
              onClick={() => setfull(true)}
              style={{ color: "#1F73B7" }}
            >
              See All {articles.length} Articles
            </a>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ArticlesList;
