import React, { useEffect, useState, useContext } from "react";
import Layout from "../components/layout/layout";
import Search from "../components/search/search";
import { Link } from "react-router-dom";
import BreadCrumb from "../components/header/breadcrumb";
import { Editor as MyEditor } from "draft-js";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromRaw,
  CompositeDecorator,
} from "draft-js";
import SearchContext from "../context/searchContext";

const ArticleDetail = () => {
  const [content, setContent] = useState(EditorState.createEmpty());
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [feedback, setFeedback] = useState(false);
  const { lang, setLang } = useContext(SearchContext);
  const { id, lan } = useParams();

  const ImageComponent = ({ src }) => (
    <img src={src} alt="" style={{ maxWidth: "100%" }} />
  );

  const mediaBlockRenderer = (block) => {
    if (block.getType() === "atomic") {
      return {
        component: MediaComponent,
        editable: false,
      };
    }
    return null;
  };

  const MediaComponent = (props) => {
    const entity = props.contentState.getEntity(props.block.getEntityAt(0));
    const type = entity.getType();
    const data = entity.getData();

    if (type === "IMAGE") {
      return <ImageComponent src={data.src} />;
    }
    return null;
  };

  const findLinkEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === "LINK"
      );
    }, callback);
  };

  const Link = (props) => {
    const { url } = props.contentState.getEntity(props.entityKey).getData();
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {props.children}
      </a>
    );
  };

  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]);

  const getBlogDetail = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API}/api/fetch/one/atricles/${id}`
      );
      console.log(res, "res");
      if (res.data.success) {
        setDetails(res.data.data);
        if (lan === "en") {
          const contentState = convertFromRaw(
            JSON.parse(res.data?.data?.content)
          );
          const editorState = EditorState.createWithContent(
            contentState,
            decorator
          );
          setContent(editorState);
        } else {
          const contentState = convertFromRaw(
            JSON.parse(res.data?.data?.contentAr)
          );
          const editorState = EditorState.createWithContent(
            contentState,
            decorator
          );
          setContent(editorState);
        }

        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (details) {
      if (lan === "en") {
        const contentState = convertFromRaw(JSON.parse(details?.content));
        const editorState = EditorState.createWithContent(
          contentState,
          decorator
        );
        setContent(editorState);
      } else {
        const contentState = convertFromRaw(JSON.parse(details?.contentAr));
        const editorState = EditorState.createWithContent(
          contentState,
          decorator
        );
        setContent(editorState);
      }
    }
  }, [lan]);

  const handleFeedback = async (helpful) => {
    const data = {};
    setFeedback(true);
    if (helpful) {
      Object.assign(data, { ishelpful: "yes" });
    } else {
      Object.assign(data, { ishelpful: "no" });
    }
    const res = await axios.put(
      `${process.env.REACT_APP_API}/api/add/article/feedback/${id}`,
      data
    );
    if (res.data.success) {
      console.log("success");
    }
  };

  useEffect(() => {
    getBlogDetail();
  }, []);
  const bread = [
    { val: "Home", href: `/${lan}`, valAr: "الصفحة الرئيسية" },
    {
      val: details?.category?.title,
      href: `/category/list/${details?.category?._id}/${lan}`,
      valAr: details?.category?.titleAr,
    },
    { val: details?.title, href: "#", valAr: details?.titleAr },
  ];

  if (loading) {
    return (
      <Layout>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "50vh",
          }}
        >
          <div class="spinner-border text-warning" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
      </Layout>
    );
  }
  return (
    <>
      <Layout>
        <div dir={lan === "en" ? "ltr" : "rtl"}>
          <div
            className="pt-2 pb-2"
            style={{ backgroundColor: "rgb(64, 64, 64)" }}
          >
            <div
              className="container"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "0px 32px",
              }}
            >
              <div>
                <BreadCrumb pages={bread} />
              </div>
            </div>
          </div>
          <div
            className="container mt-4"
            style={{ borderRadius: "5px", padding: "0px 32px" }}
          >
            <div
              className=" font-size-14"
              style={
                lan === "ar" ? { textAlign: "right" } : { textAlign: "left" }
              }
            >
              {content && (
                <MyEditor
                  editorState={content}
                  readOnly={true}
                  blockRendererFn={mediaBlockRenderer}
                />
              )}
            </div>
            <hr />
          </div>

          {!feedback && (
            <div
              className="mt-4"
              style={{ color: "whitesmoke", borderRadius: "5px" }}
            >
              <div style={{ color: "whitesmoke" }} className=" font-size-14">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <h5 style={{ color: "#232323" }}>
                    {lan === "en"
                      ? "Is this article helpful ?"
                      : "هل هذه المقالة مفيدة؟"}
                  </h5>
                  <div className="mt-3">
                    <button
                      className="btn btn-light mx-3"
                      onClick={() => handleFeedback(true)}
                      type="button"
                    >
                      {" "}
                      Yes{" "}
                    </button>
                    <button
                      className="btn btn-light"
                      onClick={() => handleFeedback(false)}
                      type="button"
                    >
                      {" "}
                      No{" "}
                    </button>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          )}

          {feedback && (
            <div style={{ color: "whitesmoke" }} className=" font-size-14 ">
              <div
                className="mt-5 mb-5"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <h5 style={{ color: "#232323" }}>Thanks for your feedback</h5>
                {/* <div className="mt-3">
                                <button className="btn btn-light mx-3" onClick={() => handleFeedback(true)} type="button"> Yes </button>
                                <button className="btn btn-light" onClick={() => handleFeedback(false)} type="button"> No </button>
                            </div> */}
              </div>
            </div>
          )}
        </div>
      </Layout>
    </>
  );
};

export default ArticleDetail;
