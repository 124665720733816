import React, { useContext } from "react";
import { Link } from "react-router-dom";
import SearchContext from "../../context/searchContext";
import { useParams } from "react-router-dom";

const Footer = () => {
  const { lang, setlang } = useContext(SearchContext);
  const { id, lan } = useParams();

  return (
    <div
      className="footer-middle style-two"
      style={{ color: "white", backgroundColor: "rgb(64, 64, 64)" }}
      dir={lan === "en" ? "ltr" : "rtl"}
    >
      {/* <div className="container">
        <div className="footer-bg"> */}
      {/* <div className="row" dir={locale === 'ar' ? "rtl" : "ltr"}>
                        <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap", justifyContent: "flex-start" }} className="col-lg-12 col-sm-12 mbl-icons" >
                            <div>
                                <SVGImage src={"/assets/images/footer/visa.svg"} alt="visa" width={100} height={100} />
                            </div>
                            <div className="mx-md-5 mx-lg-5 ml-3">
                                <SVGImage src={"/assets/images/footer/master.svg"} alt="visa" width={100} height={100} />
                            </div>

                            <div className="mx-md-5 mx-lg-5 ml-3">
                                <SVGImage src={"/assets/images/footer/money.svg"} alt="visa" width={100} height={100} />
                            </div>

                            <div className="mx-md-5 mx-lg-5 ml-2">
                                <SVGImage src={"/assets/images/footer/tether.svg"} alt="visa" width={100} height={100} />
                            </div>

                            <div className="mx-md-5 mx-lg-5 ml-3">
                                <SVGImage src={"/assets/images/footer/sticpay.svg"} alt="visa" width={100} height={100} />
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="widget widgets-company-info mb-4 mb-lg-0">
                                <div className="company-info-desc pr-2" style={locale === "ar" ? { textAlign: "right" } : null}>
                                    <h4 s className="widget-title"> <FormattedMessage id={`footer_about_us_h1`} /> </h4>
                                    <p>
                                        <span style={{ color: "#FCCD0A" }}><FormattedMessage id={`footer_about_us_p_1`} /> </span> <FormattedMessage id={`footer_about_us_p_2`} /></p>
                                </div>

                                <div className="follow-company-icon">
                                    <a className="social-icon-color" target="_blank" href="https://www.facebook.com/primex.brokers?mibextid=LQQJ4d">
                                        {" "}
                                        <FaFacebook />{" "}
                                    </a>
                                    <a className="social-icon-color2" target="_blank" href="https://www.instagram.com/primex_broker/?igshid=OGQ5ZDc2ODk2ZA%3D%3D">
                                        {" "}
                                        <FaInstagram />{" "}
                                    </a>
                                    <a className="social-icon-color1" target="_blank" href="https://x.com/Primex_broker">
                                        {" "}
                                        <FaTwitter />{" "}
                                    </a>
                                    <a className="social-icon-color3" target="_blank" href="https://www.youtube.com/channel/UC_B3x7k-gNQpDW7YEB8Lg0g">
                                        {" "}
                                        <FaYoutube />{" "}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-6">
                            <div className="widget widget-nav-menu">
                                <h4 className="widget-title"><FormattedMessage id={`footer_company_h1`} /></h4>
                                <div className="menu-quick-link-content">
                                    <ul className="footer-menu">
                                        <li>
                                            <Link legacyBehavior href="/">
                                                <FormattedMessage id={`footer_company_list_1`} />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link legacyBehavior href="/about">
                                                <FormattedMessage id={`footer_company_list_2`} />
                                            </Link>
                                        </li>{" "}
                                        <li>
                                            <Link legacyBehavior href="/contact">
                                                <FormattedMessage id={`footer_company_list_3`} />
                                            </Link>
                                        </li>

                                        <li>
                                            <Link legacyBehavior href="/complaints">
                                                <FormattedMessage id={`footer_company_list_5`} />
                                            </Link>
                                        </li>{" "}
                                        <li>
                                            <Link legacyBehavior href="/privacy-policy">
                                                <FormattedMessage id={`footer_company_list_6`} />
                                            </Link>
                                        </li>{" "}
                                        <li>
                                            <Link legacyBehavior href="/terms/conditions">

                                                <FormattedMessage id={`footer_company_list_7`} />
                                            </Link>
                                        </li>{" "}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="widget widget-nav-menu">
                                <h4 className="widget-title"> <FormattedMessage id={`footer_services_h1`} /> </h4>
                                <div className="menu-quick-link-content">
                                    <ul className="footer-menu">
                                        <li>
                                            <Link legacyBehavior href="/account-types">
                                                <FormattedMessage id={`footer_services_list_0`} />
                                            </Link>
                                        </li>{" "}
                                        <li>
                                            <Link style={{ color: "whitesmoke" }} href="/copy-program">
                                                <FormattedMessage id={`footer_services_list_1`} />
                                            </Link>
                                        </li>{" "}
                                        <li>
                                            <Link style={{ color: "whitesmoke" }} href="/service-details/ibprogram/">
                                                <FormattedMessage id={`footer_services_list_2`} />
                                            </Link>
                                        </li>{" "}
                                        <li>
                                            <Link legacyBehavior href="/instruments">
                                                <FormattedMessage id={`footer_services_list_3`} />
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-3 col-6">
                            <div className="widget widget-nav-menu">
                                <h4 className="widget-title"> <FormattedMessage id={`platform_h`} /> </h4>
                                <div className="menu-quick-link-content">
                                    <ul className="footer-menu">
                                        <li>
                                            <a style={{ color: "whitesmoke" }} href="https://download.mql5.com/cdn/web/primex.brokers.ltd/mt5/primexbrokers5setup.exe">
                                                <FormattedMessage id={`platform_li_1`} />{" "}
                                            </a>
                                        </li>{" "}
                                        <li>
                                            <a style={{ color: "whitesmoke" }} href="https://download.mql5.com/cdn/mobile/mt5/android?server=PrimeXBrokers-Demo,PrimeXBrokers-Live" target="_blank">
                                                <FormattedMessage id={`platform_li_2`} />
                                            </a>
                                        </li>{" "}
                                        <li>
                                            <a style={{ color: "whitesmoke" }} href="https://download.mql5.com/cdn/mobile/mt5/ios?server=PrimeXBrokers-Demo,PrimeXBrokers-Live" target="_blank">
                                                <FormattedMessage id={`platform_li_3`} />
                                            </a>
                                        </li>
                                        <li>
                                            <a style={{ color: "whitesmoke" }} href="https://getctrader.com/primexbroker/ctrader-primexbroker-setup.exe">
                                                <FormattedMessage id={`platform_li_4`} />{" "}
                                            </a>
                                        </li>{" "}
                                        <li>
                                            <a style={{ color: "whitesmoke" }} href="https://play.google.com/store/apps/details?id=com.primexbroker.app" target="_blank">
                                                <FormattedMessage id={`platform_li_5`} />
                                            </a>
                                        </li>{" "}
                                        <li >
                                            <a style={{ color: "whitesmoke" }} href="https://itunes.apple.com/cy/app/spotware-ctrader/id767428811?mt=8" target="_blank">
                                                <FormattedMessage id={`platform_li_6`} />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                       
                    </div> */}
      {/* <div className="foorer-shape"></div>
        </div> */}
      {/* </div> */}
      {/* legal start */}
      <div className="company-instructions">
        <div className="container">
          <p
            style={
              lan === "ar" ? { textAlign: "right" } : { textAlign: "left" }
            }
          >
            <span>{lan === "en" ? "Location" : "الموقع"}</span>{" "}
            <p className="footer-text mb-1">
              <span style={{ color: "#fff", fontWeight: "600" }}>
                {lan === "en"
                  ? "South Africa HQ: "
                  : "المقر الرئيسي في جنوب أفريقيا: "}
              </span>
              {lan === "en"
                ? "Suit no 8, First floor, Katherine and West building, 114 West Street, Sandown, Sandton 2196, South Africa."
                : "جناح رقم 8، الطابق الأول، مبنى كاثرين آند ويست، 114 شارع ويست، سانداون، ساندتون 2196، جنوب أفريقيا. "}
            </p>
            <p className="footer-text">
              <span style={{ color: "#fff", fontWeight: "600" }}>
                {lan === "en"
                  ? "UAE Rep. Office: "
                  : "مكتب التمثيلي في دولة الإمارات العربية المتحدة: "}
              </span>
              {lan === "en"
                ? "Office 2401, Westburry Tower 1, Business Bay, Dubai, UAE."
                : "مكتب 2401، برج وستبري 1،الخليج التجاري، دبي، الإمارات العربية المتحدة. "}
            </p>
          </p>
          <p
            style={
              lan === "ar" ? { textAlign: "right" } : { textAlign: "left" }
            }
          >
            <span>
              {lan === "en"
                ? "PRIMEX BROKER (PTY) LTD"
                : "برايم اكس بروكر (بي تي واي) المحدودة "}
            </span>{" "}
            <p className="footer-text">
              {" "}
              {lan === "en"
                ? "company registered in South Africa with registration number 2021/590477/07 and register address : 74 Walmer Street, Sydenham, Johannesburg, Gauteng , 2192, South Africa."
                : "شركة مسجلة في جنوب إفريقيا برقم التسجيل ٢٠٢١/٥٩٠٤٧٧/٠٧ وعنوان التسجيل: ٧٤ شارع والمر، سيدنهام، جوهانسبرج، جوتنج، ٢١٩٢ جنوب أفريقيا"}
            </p>
          </p>
          <p
            style={
              lan === "ar" ? { textAlign: "right" } : { textAlign: "left" }
            }
          >
            <span>
              {lan === "en"
                ? "PRIMEX BROKER (PTY) LTD"
                : "برايم اكس بروكر (بي تي واي) المحدودة "}
            </span>{" "}
            <p className="footer-text">
              {" "}
              {lan === "en"
                ? "regulated and authorized to provide financial services by South Africa Financial Sector Conduct Authority (FSCA) under license number "
                : "منظمة ومرخصة لتقديم الخدمات المالية من قبل هيئة سلوك القطاع المالي في جنوب إفريقيا (FSCA) بموجب رقم الترخيص "}
              <Link
                to="https://www.fsca.co.za/Fais/Search_FSP.htm"
                className="footer-legal-link"
                target="_blank"
              >
                {lan === "en" ? "(FSP No. 51864)." : "FSP  رقم ٥١٨٦٤"}
              </Link>
            </p>
          </p>
          <p
            style={
              lan === "ar" ? { textAlign: "right" } : { textAlign: "left" }
            }
          >
            <span>
              {lan === "en" ? "Restricted Regions:" : "المناطق المحظورة"}
            </span>{" "}
            <p className="footer-text">
              {" "}
              {lan === "en"
                ? "PrimeX Broker PTY LTD does not provide services for citizens/residents of the United States, Canada, Cuba, Myanmar, North Korea, Iran. The services of PrimeX Broker PTY LTD are not intended for distribution to, or use by, any person in any country or jurisdiction where such distribution or use would be contrary to local law or regulation."
                : "لا تقدم برايم اكس بروكر خدمات للمواطنين/المقيمين في الولايات المتحدة وكندا وكوبا وميانمار وكوريا الشمالية وإيران. خدمات برايم اكس بروكر ليست مخصصة للتوزيع أو الاستخدام من قبل أي شخص في أي بلد أو ولاية قضائية حيث يكون هذا التوزيع أو الاستخدام مخالفاً للقانون أو اللوائح المحلية."}
            </p>
          </p>
          <p
            style={
              lan === "ar" ? { textAlign: "right" } : { textAlign: "left" }
            }
          >
            <span>{lan === "en" ? "Risk Warning:" : "تحذير من المخاطر"} </span>{" "}
            <p className="footer-text">
              {" "}
              {lan === "en"
                ? "Trading Forex and Leveraged Financial Instruments involves significant risk and can result in the loss of your invested capital. You should not invest more than you can afford to lose and should ensure that you fully understand the risks involved."
                : "ينطوي تداول الفوركس والأدوات المالية ذات الرافعة المالية على مخاطر كبيرة ويمكن أن يؤدي إلى خسارة رأس المال المستثمر. يجب ألا تستثمر أكثر مما يمكنك تحمل خسارته، ويجب عليك التأكد من أنك تفهم تماماً المخاطر التي ينطوي عليها التداول."}
            </p>
          </p>
        </div>
      </div>
      {/* legal start */}
      <div className="footer-bottom-area d-flex align-items-center">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div
              className="col-md-4 footer-logo"
              style={
                lan === "ar" ? { textAlign: "right" } : { textAlign: "left" }
              }
            >
              <div className="consen-logo" style={{ display: "inline" }}>
                <Link legacyBehavior href={`/${lan}`}>
                  <a className="logo_thumb" title="consen">
                    <img src="/assets/images/logo.png" alt="logo" />
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-md-8">
              <div className="footer-bottom-content">
                <div className="footer-bottom-content-copy d-md-flex d-block justify-content-end">
                  <p className="footer-text">
                    © 2022 - 2024{" "}
                    <span>
                      {" "}
                      <span>PrimeX Broker LTD </span>
                      {lan === "en"
                        ? "all rights reserved."
                        : "جميع الحقوق محفوظة لـ"}
                    </span>
                  </p>
                  <div className="header_top_menu_icon_inner d-block d-md-none d-xl-block text-center">
                    <ul
                      className={`${
                        lan === "en" ? "" : "me-md-5 me-sm-0"
                      } social-box mb-0`}
                      style={
                        lan === "en"
                          ? { paddingLeft: "2rem" }
                          : { paddingRight: "2rem", paddingLeft: "0px" }
                      }
                    >
                      <li className="facebook">
                        <a
                          href="https://www.facebook.com/primex.brokers?mibextid=LQQJ4d"
                          target="_blank"
                          className="fab fa-facebook-f"
                        />
                      </li>
                      <li className="twitter">
                        <a
                          href="https://www.instagram.com/primex_broker/?igshid=OGQ5ZDc2ODk2ZA%3D%3D"
                          target="_blank"
                          className="fab fa-instagram"
                        />
                      </li>
                      <li className="linkedin">
                        <a
                          href="https://twitter.com/Primex_broker"
                          target="_blank"
                          className="fab fa-twitter"
                        />
                      </li>
                      <li className="instagram">
                        <a
                          href="https://www.youtube.com/channel/UC_B3x7k-gNQpDW7YEB8Lg0g"
                          target="_blank"
                          className="fab fa-youtube"
                        />
                      </li>
                      <li className="youtube">
                        <a
                          href="https://www.linkedin.com/company/primex-broker"
                          target="_blank"
                          className="fab fa-linkedin-in"
                        />
                      </li>
                      <li className="telegram">
                        <a
                          href="https://t.me/PrimeX_Broker"
                          target="_blank"
                          className="fab fa-telegram"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
