import React, { useState } from 'react';
import SearchContext from './searchContext';
import { useParams } from 'react-router-dom';

const MyProvider = ({ children }) => {
  const params = useParams();

  const language = params.lan;
  console.log(language, "language");
  const [list, setList] = useState([])
  const [lang, setLang] = useState( language ? language :"en")
    
  return (
    <SearchContext.Provider value={{
        list, setList,
        lang, setLang
         }}>
      {children}
    </SearchContext.Provider>
  );
};

export default MyProvider;