import React, { useEffect, useState, useContext } from "react";
import SearchContext from "../context/searchContext";
import Layout from "../components/layout/layout";
import Search from "../components/search/search";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import About from "./about";

const Home = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [categories, setCategories] = useState([]);
  const [listData, setListData] = useState([]);
  const [input, setInput] = useState("");
  const { list, setList } = useContext(SearchContext);
  const { lang, setLang } = useContext(SearchContext);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { id, lan } = useParams();

  const handleInput = (val) => {
    setInput(val);
  };

  const SearchData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API}/api/fetch/all/search/${input}`
      );
      if (res.data.success) {
        console.log(res.data.data, "search result");
        setShowPopup(true);
        setList(res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (input !== "") {
      SearchData();
    }
  }, [input]);
  const data = [
    {
      value: "Getting Started",
    },
    {
      value: "MT5 Terminal",
    },
    {
      value: "cTrader Terminal",
    },
    {
      value: "cTrader Terminal",
    },
    {
      value: "cTrader Terminal",
    },
    {
      value: "cTrader Terminal",
    },
    {
      value: "cTrader Terminal",
    },
    {
      value: "cTrader Terminal",
    },
    {
      value: "cTrader Terminal",
    },
  ];

  const getCategories = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API}/api/fetch/all/public/article/categories`
      );
      console.log(res, "res");
      if (res.data.success) {
        setCategories(res.data.data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  console.log(categories, "cat");

  useEffect(() => {
    getCategories();
  }, []);

  if (loading) {
    return (
      <Layout>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "50vh",
          }}
        >
          <div class="spinner-border text-warning" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="App" dir={lan === "en" ? "ltr" : "rtl"}>
        <header
          className="App-header app-header-bg mb-2"
          style={
            {
              // background: "url(/assets/images/resource/braitcam.png)",
              // backgroundPosition: "center",
              // backgroundSize: "cover",
            }
          }
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <section
              id="main-content"
              className="section hero"
              style={{ width: "60%" }}
            >
              <div>
                <h1>
                  <b style={{ fontSize: "48px", color: "white" }}>
                    {lan === "en"
                      ? "PrimeX Help Center"
                      : "مركز مساعدة برايم اكس"}
                    &nbsp;
                  </b>
                </h1>
                <Search
                  value={input}
                  height={60}
                  popup={showPopup}
                  onChange={(e) => handleInput(e.target.value)}
                  setPopup={() => {
                    setShowPopup(true);
                  }}
                  closePopup={() => {
                    setShowPopup(false);
                  }}
                />
              </div>
            </section>
          </div>
        </header>
        <div className="container list-container pt-5 pb-5">
          <div className="service-page-title">
            <h1>{lan === "en" ? "Article Categories" : "فئات المواضيع"}</h1>
          </div>
          <div>
            <ul
              className="support-list"
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                paddingLeft: "0px",
                marginBottom: "0px",
              }}
            >
              {categories.map((val, i) => (
                <li
                  onClick={() => navigate(`/category/list/${val._id}/${lan}`)}
                  className="slider-button h-slider-btn b-hover mt-3"
                  key={i}
                >
                  {" "}
                  {lan === "en" ? val.title : val.titleAr}
                </li>
              ))}

              {/* <li className="slider-button b-hover">  </li>
                            <li className="slider-button b-hover">  </li> */}
            </ul>
          </div>
        </div>
        <About />
      </div>
    </Layout>
  );
};

export default Home;
