import logo from "./logo.svg";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/home";
import Navbar from "./components/header/navbar";
import ArticlesList from "./pages/articlesList";
import "./App.css";
import { useEffect, useState } from "react";
import SearchProvider from "./context/searchProvider";
import ArticleDetail from "./pages/articleDetail";

function App() {
  // useEffect(()=>{
  //   window.location.replace('/en')
  // },[])

  return (
    <SearchProvider>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route path="/:lan" element={<Home />}></Route>
          <Route
            path="/category/list/:id/:lan"
            element={<ArticlesList />}
          ></Route>
          <Route
            path="/article/detail/:id/:lan"
            element={<ArticleDetail />}
          ></Route>
          <Route path="/" element={<Navigate replace to="/en" />} />
        </Routes>
      </BrowserRouter>
    </SearchProvider>
  );
}

export default App;
