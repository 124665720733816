import React, { Fragment, useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import SearchContext from "../../context/searchContext";
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const Navbar = () => {
  const { id, lan } = useParams();

  const [toggle, setToggle] = useState(false);
  const { lang, setLang } = useContext(SearchContext);
  const location = useLocation();
  const navigate = useNavigate();

  console.log(location, "location");
  const stickyNav = () => {
    window.addEventListener("scroll", () => {
      let offset = window.scrollY;
      const sticky = document.querySelectorAll(".header____");
      for (let i = 0; i < sticky.length; i++) {
        const stick = sticky[i];
        if (stick) {
          if (offset > 10) {
            stick.classList.add("sticky");
          } else {
            stick.classList.remove("sticky");
          }
        }
      }
    });
  };

  const handleChange = (e) => {
    console.log(e.target.value);
    setLang(e.target.value);
    const currentLanguageCode = location.pathname.match(/\/(en|ar)(\/|$)/)?.[1];

    if (currentLanguageCode && currentLanguageCode !== e.target.value) {
      // This regex ensures that the language code is either at the end of the URL
      // or followed by a slash, but not part of another word
      const regex = new RegExp(`/${currentLanguageCode}(?=/|$)`);
      const newPath = location.pathname.replace(regex, `/${e.target.value}`);
      navigate(newPath);
    }
  };

  useEffect(() => {
    stickyNav();
  }, []);

  return (
    <Fragment>
      {/* <div className="header_top_menu d-flex align-items-center landing_header ">
        <div
          dir={lan === "en" ? "ltr" : "rtl"}
          className="container nav-container"
        >
          <div className="row d-flex align-items-center justify-content-center">
            <div className="col-xl-7 col-lg-7 col-md-5 col-sm-6 col-7">
              <div className="header_top_menu_address">
                <div className="header_top_menu_text">
                  <p className="mb-0">
                    {lan === "en"
                      ? "Welcome! to PrimeX Broker Help Center"
                      : "مرحباً بك! في مركز مساعدة برايم اكس بروكر"}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-7 col-sm-6 col-5 pr-0">
              <div
                className="header_top_menu_icon"
                style={
                  lan === "en" ? { textAlign: "right" } : { textAlign: "left" }
                }
              >
                <div className="menu-title d-md-inline-block d-none">
                  <h6>{lan === "en" ? "FOLLOW US" : "تابعنا "}</h6>
                </div>
                <div className="header_top_menu_icon_inner">
                  <ul
                    className={`${
                      lan === "en" ? "" : "me-md-5 me-sm-0"
                    } social-box mb-0`}
                    style={
                      lan === "en"
                        ? { paddingLeft: "2rem" }
                        : { paddingRight: "2rem", paddingLeft: "0px" }
                    }
                  >
                    <li className="facebook">
                      <a
                        href="https://www.facebook.com/primex.brokers?mibextid=LQQJ4d"
                        target="_blank"
                        className="fab fa-facebook-f"
                      />
                    </li>
                    <li className="twitter">
                      <a
                        href="https://www.instagram.com/primex_broker/?igshid=OGQ5ZDc2ODk2ZA%3D%3D"
                        target="_blank"
                        className="fab fa-instagram"
                      />
                    </li>
                    <li className="linkedin">
                      <a
                        href="https://twitter.com/Primex_broker"
                        target="_blank"
                        className="fab fa-twitter"
                      />
                    </li>
                    <li className="instagram">
                      <a
                        href="https://www.youtube.com/channel/UC_B3x7k-gNQpDW7YEB8Lg0g"
                        target="_blank"
                        className="fab fa-youtube"
                      />
                    </li>
                    <li className="youtube">
                      <a
                        href="https://www.linkedin.com/company/primex-broker"
                        target="_blank"
                        className="fab fa-linkedin-in"
                      />
                    </li>
                    <li className="telegram">
                      <a
                        href="https://t.me/PrimeX_Broker"
                        target="_blank"
                        className="fab fa-telegram"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/*==================================================*/}
      {/* End consen Header Top Menu Area Css */}
      {/*===================================================*/}
      {/*==================================================*/}
      {/* Start consen Main Menu Area */}
      {/*==================================================*/}
      <div
        id="sticky-header"
        dir={lan === "en" ? "ltr" : "rtl"}
        style={{ zIndex: 1000, width: "100%" }}
        className="consen_nav_manu style-two header____ "
      >
        <div>
          <div className="header-bg consen_nav_inner">
            <div
              className="consen_nav_logo"
              style={{
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className="logo">
                <Link to={`/${lan}`}>
                  <a className="logo_img" title="zeshi">
                    <img
                      src="/assets/images/logo2.png"
                      style={{ maxWidth: "170px" }}
                      alt="logo"
                    />
                  </a>
                </Link>
                <Link to={`/${lan}`}>
                  <a className="main_sticky" title="zeshi">
                    <img
                      src="/assets/images/logo2.png"
                      style={{ maxWidth: "170px" }}
                      alt="logo"
                    />
                  </a>
                </Link>
              </div>

              <div
                className="consen_nav_inputs"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="consen_nav_language ms-sm-5 ms-4">
                  <select
                    class="form-select mx-3"
                    aria-label="Default select example"
                    onChange={handleChange}
                  >
                    <option selected={lan === "en" ? true : false} value="en">
                      <span style={{ paddingTop: "50px" }}>English</span>{" "}
                    </option>
                    <option selected={lan === "ar" ? true : false} value="ar">
                      عربی
                    </option>
                  </select>
                </div>
                <div className="header-button  pt-3 pb-3">
                  <Link to="https://primexbroker.com/contact/en/">
                    {lan === "en" ? "Submit request" : "تقديم الطلب"}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Navbar;
